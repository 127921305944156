<template>
  <div>
    <filter-slot
      :filter="filters"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      :send-multiple-sms="false"
      :change-columns-by-client="true"
      @reload="updateTable"
    >
      <template #buttons>
        <!-- Button Sms -->
        <!-- <b-button
          variant="success"
          class="ml-1"
          :disabled="lengthClients"
          @click="openModalSendSms"
        >
          <feather-icon icon="MessageCircleIcon" class="mr-50" />Send SMS
        </b-button> -->
        <!-- Button Send Email -->
        <b-button
          variant="success"
          class="ml-1"
          :disabled="lengthClients"
          @click="openModalSendEmail"
        >
          <feather-icon icon="MailIcon" class="mr-50" />Send Email
        </b-button>
      </template>

      <template #custom-vselect>
        <FilterStatusAccount :filters="filters[3]" />
      </template>

      <template #table>
        <b-table
          slot="table"
          ref="refClientsList"
          small
          no-provider-filtering
          :items="myProvider"
          :fields="visibleFields"
          primary-key="id"
          table-class="text-nowrap"
          responsive="sm"
          show-empty
          sticky-header="70vh"
          :busy.sync="isBusy"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <!-- Selected Title -->
          <template #head(selected)>
            <b-form-checkbox v-model="selectAll" @input="selectedAll" />
          </template>
          <!-- Selected -->
          <template v-slot:cell(selected)="data">
            <b-form-group>
              <b-form-checkbox
                v-model="clientsSms"
                :value="{
                  id: data.item.account_id,
                  name: data.item.lead_name,
                }"
                :name="data.item.account_id"
                class="mt-1"
              />
            </b-form-group>
          </template>
          <!-- Column NAME -->
          <template #cell(lead_name)="data">
            <router-link
              class
              :to="{
                name: 'boost-credit-dashboard',
                params: {
                  idClient: data.item.account_id,
                },
              }"
              target="_blank"
              >{{ data.item.lead_name }}</router-link
            >
            <br />
            <span v-if="data.item.user_responsible" class="text-warning">
              <tabler-icon
                :icon="
                  data.item.user_responsible === currentUser.user_id
                    ? 'StarIcon'
                    : 'LockIcon'
                "
                size="15"
              />
              <strong>CEO's client</strong>
            </span>
            <div>
              <span style="font-weight: 100">{{ data.item.mobile }}</span>
            </div>
          </template>
          <!-- account -->
          <template #cell(account)="data">
            <div>
              <ul style="padding-left: 0px; margin-bottom: 0px">
                <li style="list-style: none">
                  <span tyle="font-weight: 100">{{ data.item.account }}</span>
                </li>
              </ul>
              <ul style="padding-left: 0px; margin-bottom: 0px">
                <li style="list-style: none">
                  <status-account :account="data.item" />
                </li>
              </ul>
            </div>
            <b-badge v-if="data.item.claims != 0" variant="light-info">
              IN CLAIM ({{ data.item.claims }})
            </b-badge>
          </template>
          <!-- Column MP -->
          <template
            v-if="
              (moduleId != 2 && moduleId != 6) ||
              (moduleId == 6 && currentUser.role_id != 4)
            "
            #cell(monthly_amount)="data"
          >
            <div>
              <ul style="padding-left: 0px; margin-bottom: 0px">
                <li style="list-style: none">
                  {{ formatMoney(data.item.monthly_amount) }}
                </li>
              </ul>
            </div>
          </template>
          <!-- Column LP -->
          <template
            v-if="
              (moduleId != 2 && moduleId != 6) ||
              (moduleId == 6 && currentUser.role_id != 4)
            "
            #cell(last_payment)="data"
          >
            <div>
              <ul style="padding-left: 0px; margin-bottom: 0px">
                <li style="list-style: none; position: relative">
                  <span style="margin-left: 15px">
                    {{
                      data.item.last_payment == "-"
                        ? ""
                        : data.item.last_payment
                    }}
                    <b-img
                      v-if="data.item.state_lp == 1"
                      :src="assetsImg + '/images/icons/lastpayment-out.ico'"
                      style="
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translate(0, -50%);
                      "
                    />
                    <b-img
                      v-else
                      :src="assetsImg + '/images/icons/lastpayment-in.ico'"
                      style="
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translate(0, -50%);
                      "
                    />
                  </span>
                </li>
              </ul>
            </div>
          </template>

          <!-- Column PT -->
          <template
            v-if="
              (moduleId != 2 && moduleId != 6) ||
              (moduleId == 6 && currentUser.role_id != 4)
            "
            #cell(status_payment)="data"
          >
            <div>
              <ul style="padding-left: 0px; margin-bottom: 0px">
                <li style="list-style: none">
                  <status-payment :account="data.item" />
                </li>
              </ul>
            </div>
          </template>
          <!-- Column Score -->
          <template #cell(score)="data">
            <div>
              <ul style="padding-left: 0px; margin-bottom: 0px">
                <li style="list-style: none">
                  <b-badge
                    v-if="data.item.score"
                    style="width: 50px"
                    pill
                    :variant="
                      data.item.score <= 659 && !data.item.score
                        ? 'danger'
                        : data.item.score >= 660 && data.item.score <= 699
                        ? 'warning'
                        : data.item.score >= 700 && data.item.score <= 759
                        ? 'primary'
                        : data.item.score >= 760 && data.item.score <= 850
                        ? 'success'
                        : 'danger'
                    "
                  >
                    {{ data.item.score }}
                  </b-badge>
                </li>
              </ul>
            </div>
          </template>

          <!-- column GOALS -->
          <template #cell(goals)="data">
            <div id="goals">
              <ul style="padding-left: 0px; margin-bottom: 0px">
                <li v-for="(goals, index) in data.item.goals" :key="index">
                  {{ goals.name }}
                </li>
              </ul>
            </div>
          </template>
          <!-- Column ADVISOR -->
          <template #cell(state_advisor)="data">
            <div>
              <ul style="padding-left: 0px; margin-bottom: 0px">
                <li style="list-style: none">
                  <div>
                    <feather-icon
                      id="icon"
                      :title="
                        data.item.state_advisor == 1
                          ? 'Active'
                          : data.item.state_advisor == 2
                          ? 'Busy'
                          : data.item.state_advisor == 3
                          ? 'Away'
                          : 'Offline'
                      "
                      icon="CircleIcon"
                      size="13"
                      :style="
                        data.item.state_advisor == 1
                          ? 'background:green'
                          : data.item.state_advisor == 2
                          ? 'background:orange'
                          : data.item.state_advisor == 3
                          ? 'background:red'
                          : 'background:#ccc'
                      "
                    />
                    {{ data.item.advisor_name }}
                  </div>
                </li>
              </ul>
            </div>
          </template>

          <!-- Column LN -->
          <template
            v-if="
              (moduleId != 2 && moduleId != 4 && moduleId != 6) ||
              (moduleId == 6 && currentUser.role_id != 4)
            "
            #cell(s_note)="data"
          >
            <div>
              <ul
                style="padding-left: 0px; margin-bottom: 0px; list-style: none"
              >
                <li
                  class="li-created-at-account"
                  :class="
                    data.item.s_note <= 1
                      ? ''
                      : data.item.s_note <= 2
                      ? 'text-warning'
                      : 'text-danger'
                  "
                >
                  {{ data.item.last_note }}
                </li>
              </ul>
            </div>
          </template>
          <!-- column UT -->
          <template #cell(uncomplete_task)="data">
            <div>
              <ul style="padding-left: 0px; margin-bottom: 0px">
                <li style="list-style: none">
                  <feather-icon id="calendar" icon="CalendarIcon" size="20" />
                  <div
                    style="
                      position: relative;
                      bottom: 15px;
                      left: 6.7px;
                      font-size: 85%;
                      font-weight: bold;
                    "
                  >
                    {{ data.item.task }}
                  </div>
                </li>
              </ul>
            </div>
          </template>
          <!-- column enrol_date -->
          <template #cell(enrol_date)="data">
            <div>
              <ul style="padding-left: 0px; margin-bottom: 0px">
                <li style="list-style: none">
                  {{ data.item.created_at | myGlobal }}
                </li>
              </ul>
            </div>
          </template>

          <!-- COLUMN OTHER PROGRAMS -->
          <template #cell(qty_services)="{ item }">
            <ClientPrograms
              :clientData="item"
              :programName="item.program"
              :programId="item.program_id"
            />
          </template>

          <!-- Column SERVICES -->
          <template
            v-if="
              currentUser.role_id == 1 ||
              currentUser.role_id == 2 ||
              currentUser.role_id == 3
            "
            #cell(service)="data"
          >
            <b-dropdown
              v-if="
                data.item.user_responsible == currentUser.user_id ||
                data.item.user_responsible == null
              "
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
              boundary="viewport"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <!-- Add Service -->
              <b-dropdown-item
                variant="warning"
                @click="modalAddService(data.item)"
              >
                <feather-icon icon="FilePlusIcon" />
                <span class="align-middle ml-50">Add service</span>
              </b-dropdown-item>

              <!-- Change Service -->
              <b-dropdown-item
                :variant="
                  $route.matched[0].meta.module == 26 ? 'warning' : 'success'
                "
                @click="modalChangeService(data.item)"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Change service</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
          <!-- column actions -->
          <template
            v-if="
              currentUser.role_id == 1 ||
              currentUser.role_id == 2 ||
              currentUser.role_id == 3 ||
              currentUser.role_id == 4
            "
            #cell(actions)="data"
          >
            <b-dropdown
              v-if="
                data.item.user_responsible == currentUser.user_id ||
                data.item.user_responsible == null
              "
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
              boundary="viewport"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <!-- sendToCallReturns -->
              <b-dropdown-item
                v-if="
                  data.item.status_call_round == 1 && currentUser.role_id != 4
                "
                v-b-tooltip.hover.left="'Send to call rounds'"
                v-b-modal.modal-primary
                variant="primary"
                @click="
                  modalToCallOpen(
                    data.item.account_id,
                    data.item.lead_name,
                    data.item
                  )
                "
              >
                <feather-icon icon="PhoneForwardedIcon" />
                <span class="align-middle ml-50">Send to call rounds</span>
              </b-dropdown-item>
              <!-- sendSMS -->
              <b-dropdown-item
                v-b-tooltip.hover.left="'Send SMS'"
                v-b-modal.modal-primary
                @click="modalSmsOpen(data.item.account_id, data.item.lead_name)"
              >
                <feather-icon icon="MessageSquareIcon" />
                <span class="align-middle ml-50">SMS</span>
              </b-dropdown-item>
              <!-- SendEmail -->
              <b-dropdown-item
                v-b-tooltip.hover.left="'Send Email'"
                v-b-modal.modal-primary
                @click="
                  modalSendEmailOpen(data.item.account_id, data.item.lead_name)
                "
              >
                <feather-icon icon="MailIcon" />
                <span class="align-middle ml-50">Send Email</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </template>
    </filter-slot>
    <!-- Modal send sms -->
    <modal-send-sms
      v-if="modalSendSms"
      :modal-send-sms="modalSendSms"
      :name-leads="clientsSms[0]"
      :typesms="0"
      @closeModal="closeSendSms"
    />

    <!-- Modal call rounds -->
    <modal-call-rounds
      v-if="modalCall"
      :modal-call-rounds="modalCall"
      :name-leads="clientsSms"
      :type-call="0"
      @updateTable="updateTable"
      @closeModal="closeModalCallRounds"
    />
    <!-- Modal Send Email -->
    <modal-send-email
      v-if="modalSendEmail"
      :modal-send-email="modalSendEmail"
      :name-leads="clientsSms"
      :typesms="0"
      @closeModal="closeSendSms"
    />
    <!-- Modal Add Services -->
    <modal-add-service
      v-if="modalOpenAddService"
      :modal-open-add-service="modalOpenAddService"
      :details-client="detailsClient"
      :type-modal="typeModal"
      @hideModal="closeModalService"
      @changeProgram="openModalProgram"
    />
    <list-access-credentials-modal
      v-if="modalListCredentialsOn"
      :credential-id="credentialId"
      :account-number="accountNumber"
      :client-name="clientNameToPass"
      @close="closeModalListCredentials"
    />
    <!-- Component Commons -->
    <component
      :is="modalData.programs.programSelected"
      v-if="modal.programs"
      :type-modal="modalData.programs.typeModal"
      :sales-client="saleClientP"
      :programs-all="programsAll"
      :users-services="usersServices"
      :header-s="headerS"
      @closeModal="hideModalProgram"
      @changeProgram="openModalProgram"
    />
  </div>
</template>

<script>
// Table clients
import FilterSlot from "@/views/crm/views/sales-made/components/slots/FilterSlot.vue";
import fields from "@/views/boost-credit/views/clients/data/fields.clients.data";

// service
import ClientServiceBoostCredit from "@/views/boost-credit/services/clients.service";
import GeneralSupportModal from "@/views/crm/views/sales-made/components/modals/services/GeneralSupportModal.vue";
import ModalAddService from "@/views/commons/components/clients/modals/ModalAddService.vue";
import ModalCallRounds from "@/views/boost-credit/views/clients/components/clients/modals/SendToCallRounds.vue";
import ModalSendEmail from "@/views/commons/components/clients/modals/ModalSendEmail.vue";
import ModalSendSms from "@/views/commons/components/clients/modals/ModalSendSms.vue";
import StatusPayment from "@/views/commons/components/clients/stylescomponents/StatusPayment.vue";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";
import ClientService from "@/views/commons/components/clients/services/clients.services";
import LeadService from "@/views/commons/components/lead-programs/service/lead.service";
import FiltersClientsData from "@/views/boost-credit/views/clients/data/filters.clients.data";
import ListAccessCredentialsModal from "@/views/boost-credit/views/clients/components/clients/modals/ListAccessCredentialsModal.vue";
import BoostCreditModal from "@/views/crm/views/sales-made/components/modals/services/BoostCreditModal.vue";
import ClientPrograms from "@/views/commons/components/client-programs/ClientPrograms.vue";
import { mapGetters, mapActions } from "vuex";

import NcrLeadsService from "@/views/administration/views/ncr-leads/services/NcrLeads.service.js";
import FilterStatusAccount from "@/views/commons/components/clients/stylescomponents/FilterStatusAccount.vue";
import { modifyVisibility } from "@/helpers/fields-table";
import Vue from "vue";
export default {
  components: {
    FilterSlot,
    ListAccessCredentialsModal,
    BoostCreditModal,
    GeneralSupportModal,
    ModalCallRounds,
    ModalAddService,
    ModalSendEmail,
    ModalSendSms,
    StatusPayment,
    StatusAccount,
    NcrLeadsService,
    FilterStatusAccount,
    ClientPrograms,
  },
  data() {
    return {
      headerS: {},
      goals: [],
      clientNameToPass: "",
      modalData: {
        programs: {
          programSelected: "",
          typeModal: 0,
          salesClient: {},
        },
      },
      modal: {
        programs: false,
      },
      modalOpenAddService: false,
      typeModal: null,
      clients: null,
      clientsSms: [],
      selectAll: 0, // total
      allClients: [], // lista de listas
      arrayCurrentPage: [],
      filters: FiltersClientsData,
      fields,
      assetsImg: process.env.VUE_APP_BASE_URL_ASSETS,
      totalRows: 0,
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      totalAmount: 0,
      sortBy: "",
      sortDesc: true,
      startPage: null,
      endPage: "",
      nextPage: "",
      toPage: null,
      isBusy: false,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Client...",
        model: "",
      },
      tiemposegundos: 30,
      tiempominutos: 0,
      time1: true,
      pnttime: false,
      advisor: "",
      modalSendSms: false,
      modalSendEmail: false,
      modalCall: false,
      serviceOptions: [],
      actionOptions: [],
      detailsClient: {},
      saleClientP: {},
      programsAll: [],
      usersServices: [],
      items: [],
      event: null,
      modalListCredentialsOn: false,
      credentialId: "",
      accountNumber: "",
      typeAddChange: null,
      showSummaryModal: false,
      itemSelected: {},
    };
  },
  async created() {
    await this.usersPrograms();
    this.validateColumnsByRole();
    await this.usersAllPrograms();
    this.listTypeGoal();
    this.getAllStatusClientAccount();
  },
  async mounted() {
    // if (this.currentUser.role_id == 3) {
    //   modifyVisibility(this.fields, "state_advisor", false);
    // }
    this.serviceOptions.push("addService");
    this.serviceOptions.push("changeService");
    this.actionOptions.push("sendToCallReturns");
    this.actionOptions.push("sendSMS");
    this.actionOptions.push("sendEmail");
  },
  methods: {
    summaryModal(item) {
      this.itemSelected = item;
      this.showSummaryModal = true;
    },
    updateTable() {
      this.$refs.refClientsList.refresh();
    },
    listTypeGoal() {
      amgApi
        .post("/commons/list-type-goal-orderby")
        .then((response) => {
          this.goals = response.data;
          this.filters[8].options = this.goals;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // actions
    async modalToCallOpen(id, name, account, nameAccount, nameProgram) {
      const result = await this.showConfirmSwal("Are you sure?");
      if (result.value) {
        this.clientsSms = [
          {
            id,
            name,
            account,
            nameAccount,
            nameProgram,
          },
        ];
        this.modalCall = true;
      }
    },
    openModalListCredentials(id, accNumber, clientName) {
      this.modalListCredentialsOn = true;
      this.credentialId = id;
      this.accountNumber = accNumber;
      this.clientNameToPass = clientName;
    },
    closeModalListCredentials() {
      this.modalListCredentialsOn = false;
    },
    async modalSendEmailOpen(id, name) {
      const result = await this.showConfirmSwal("Are you sure?");
      if (result.value) {
        this.clientsSms = [{ id, name }];
        this.modalSendEmail = true;
      }
    },
    async modalSmsOpen(id, name) {
      const result = await this.showConfirmSwal("Are you sure?");
      if (result.value) {
        this.clientsSms = [{ id, name }];
        this.modalSendSms = true;
      }
    },
    // buttons
    openModalCallRounds() {
      this.modalCall = true;
    },
    openModalSendSms() {
      this.modalSendSms = true;
    },
    openModalSendEmail() {
      this.modalSendEmail = true;
    },
    closeSendSms() {
      this.modalSendSms = false;
      this.modalSendEmail = false;
    },
    closeModalCallRounds() {
      this.modalCall = false;
    },
    validateColumnsByRole() {
      if([2,3].includes(this.currentUser.role_id)) {
        this.filters[2].visible = true;
      } else if (this.currentUser.role_id === 4) {
        modifyVisibility(this.fields, "service", false);
        modifyVisibility(this.fields, "access_cred", true);
      }
    },
    changeTypePayment(type) {
      if (type == "paymentType") {
        const { filOne, filTwo } =
          this.currentUser.role_id != 4
            ? { filOne: 4, filTwo: 5 }
            : { filOne: 3, filTwo: 4 };
        if (this.filters[filOne].model == 1) {
          this.filters[filTwo].visible = true;
        } else {
          this.filters[filTwo].model = null;
          this.filters[filTwo].visible = false;
        }
      }
    },
    selectedAll() {
      if (this.selectAll == 1) {
        const nameCl = [];
        this.clients.map((dato) => {
          nameCl.push({
            id: dato.account_id,
            name: dato.lead_name,
          });
        });
        const value = this.allClients.find(
          (element) => element.currentPage == this.paginate.currentPage
        );
        if (!value) {
          this.allClients.push({
            currentPage: this.paginate.currentPage,
            array: nameCl,
          });
        }
      }
      if (this.selectAll == 0) {
        const value = this.allClients.find(
          (element) => element.currentPage == this.paginate.currentPage
        );
        if (value) {
          const index = this.allClients.indexOf(value);
          this.allClients.splice(index, 1);
        }
      }
      this.clientsSms = [];
      for (let i = 0; i < this.allClients.length; i++) {
        this.clientsSms = [...this.clientsSms, ...this.allClients[i].array];
      }
    },
    async myProvider(ctx) {
      let sortBy = 5;
      let sortDirection = "desc";
      if (ctx.sortBy === "lead_name") {
        sortBy = 2;
        if (ctx.sortDesc) sortDirection = "desc";
        else sortDirection = "asc";
      } else if (ctx.sortBy === "account") {
        sortBy = 4;
        if (ctx.sortDesc) sortDirection = "desc";
        else sortDirection = "asc";
      } else if (ctx.sortBy === "monthly_amount") {
        sortBy = 43;
        if (ctx.sortDesc) sortDirection = "desc";
        else sortDirection = "asc";
      } else if (ctx.sortBy === "last_payment") {
        sortBy = 44;
        if (ctx.sortDesc) sortDirection = "desc";
        else sortDirection = "asc";
      } else if (ctx.sortBy === "uncomplete_task") {
        sortBy = 46;
        if (ctx.sortDesc) sortDirection = "desc";
        else sortDirection = "asc";
      } else if (ctx.sortBy === "status_payment") {
        sortBy = 48;
        if (ctx.sortDesc) sortDirection = "desc";
        else sortDirection = "asc";
      } else if (ctx.sortBy === "s_note") {
        sortBy = 45;
        if (ctx.sortDesc) sortDirection = "desc";
        else sortDirection = "asc";
      } else if (ctx.sortBy === "enrol_date") {
        sortBy = 5;
        if (ctx.sortDesc) sortDirection = "desc";
        else sortDirection = "asc";
      }
      try {
        let module = 0;
        switch (parseInt(this.moduleId)) {
          case 2:
            module = this.program;
            break;
          case 4:
            module = this.program;
            break;
          case 3:
            module = 1;
            break;
          case 7:
            module = 2;
            break;
          case 6:
            module = 3;
            break;
          case 5:
            module = 4;
            break;
          case 8:
            module = 5;
            break;
          case 10:
            module = 6;
            break;
        }
        if (parseInt(this.moduleId) != 2 && parseInt(this.moduleId) != 4) {
          if (
            this.currentUser.role_id == 1 ||
            this.currentUser.role_id == 2 ||
            this.currentUser.role_id == 3 ||
            (this.currentUser.role_id == 4 && this.moduleId == 7)
          ) {
            // Boost Credit requested to see all clients, no matter which advisor is logged in
            this.advisor = this.filters[2].model;
          } else {
            this.advisor = null;
          }
        } else {
          this.advisor = this.filters[2].model;
        }
        const params = {
          per_page: ctx.perPage,
          text: this.filterPrincipal.model,
          from: this.filters[0].model,
          to: this.filters[1].model,
          program: module,
          orderby: sortBy,
          order: sortDirection,
          status: this.filters[3].model,
          advisor: this.advisor,
          type: this.filters[4].model,
          day: this.paymentDay,
          score: this.filters[6].model,
          goal: this.filters[8].model,
          rol_id: this.currentUser.role_id,
          typescredential: this.filters[7].model,
          page: ctx.currentPage,
        };
        const data = await ClientServiceBoostCredit.searchClients(params);

        this.startPage = data.from;
        this.paginate.currentPage = data.current_page;
        this.paginate.perPage = data.per_page;
        this.nextPage = this.startPage + 1;
        this.endPage = data.last_page;
        this.totalRows = data.total;
        this.toPage = data.to;
        this.items = data.data.map((item) => ({
          ...item,
          ...JSON.parse(item.transactions),
          goals: JSON.parse(item.goals ?? "[]"),
          ...(item.note_account ? JSON.parse(item.note_account) : null),
        }));
        this.clients = this.items;
        return this.items || [];
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    async usersPrograms() {
      try {
        const params = {
          idmodule: this.moduleId,
          iduser: this.currentUser.user_id,
          idrole: this.currentUser.role_id,
        };
        if (this.currentUser.role_id) {
          const data = await ClientService.usersPrograms(params);
          this.filters[2].label = "Advisor";
          this.filters[2].selectText = "user_name";
          this.filters[2].options = data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async searchPrograms(lead_id) {
      try {
        const response = await ClientService.searchClientPayment({
          id: lead_id,
        });
        if (response.status == 200) {
          const programs = response.data;
          const data = await ClientService.programs();
          this.programsAll = data.data;
          programs.sort((a, b) => a.program_id - b.program_id);
          for (let i = 0; i < this.programsAll.length; i++) {
            for (let y = 0; y < programs.length; y++) {
              if (this.programsAll[i].id == programs[y].program_id) {
                this.programsAll.splice(i, 1);
              }
            }
          }
          const sper = this.currentUser.sale_permission;
          const spn = [];
          if (sper.length != this.programsAll.length) {
            sper.map((spe) => {
              this.programsAll.map((pro) => {
                if (spe.id == pro.id) {
                  spn.push(pro);
                }
              });
            });
            this.programsAll = spn;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async usersAllPrograms() {
      try {
        const data = await ClientService.usersAllPrograms({
          id: this.moduleId,
        });
        this.usersServices = data.data; // .filter(item => item.id != 1);
        this.usersServices.unshift({ id: 1, user_name: "CEO" });
      } catch (error) {
        console.log(error);
      }
    },
    async modalChangeService(data) {
      const result = await this.showConfirmSwal("Are you sure change service?");
      if (result.value) {
        this.typeAddChange = 2;
        await this.createEvent(data);
        this.modalGeneralService(data, 4);
      }
    },
    onRowProcess(id) {},
    async modalAddService(data) {
      const result = await this.showConfirmSwal(
        "Are sure want to add new service?"
      );
      if (result.value) {
        this.typeAddChange = 1;
        await this.createEvent(data);
        this.modalGeneralService(data, 3);
      }
    },
    async modalSendSMS(data) {
      const result = await this.showConfirmSwal("Are sure want to send SMS?");
      if (result.value) {
        this.modalGeneralService(data, 2);
      }
    },
    async modalSendEmailMenu(data) {
      const result = await this.showConfirmSwal("Are sure want to send Email?");
      if (result.value) {
        this.modalGeneralService(data, 2);
      }
    },
    ...mapActions("crm-store", ["SET_PROGRAM", "SET_CATCHER", "SET_SELLER"]),
    modalGeneralService(data, type) {
      this.detailsClient = data;
      this.detailsClient.accounts = [
        {
          program: data.program,
        },
      ];
      this.typeModal = type;
      Vue.set(this.detailsClient, "event_id", this.event)
      this.SET_PROGRAM(null);
      this.SET_CATCHER(null);
      this.SET_SELLER(null);
      this.modalOpenAddService = true;
    },
    closeModalService() {
      this.modalOpenAddService = false;
    },
    async createEvent(data) {
      const response = await LeadService.createLeadEvent({
        user_id: this.currentUser.user_id,
        lead_id: data.lead_id,
      });
      this.event = response.id;
    },
    async openModalProgram(data) {
      const param = {
        prices: null,
        observation: "Service",
        contract: 1,
        program: data.program.id,
        fee: null,
        suggested: null,
        otherpricesp: null,
        event: this.event,
        json_noce: null,
        stateid: 0,

        // Diferents to add change Services
        account: this.detailsClient.account_id,
        captured: data.captured.id,
        seller: data.seller.id,
        type: this.typeAddChange,
        user_id: this.currentUser.user_id,
        module: this.moduleId,
        id_score: null,
        json_ce: null,
        type_client: "CLIENT",
      };
      try {
        const result = await LeadService.insertSaleMade(param);
        if (result.status == 200) {
          if (data.program.id == 4) {
            // copy settlements result (ncr) to sales made modal debt solution
            const params = {
              ca_id_first_program: this.detailsClient.account_id,
              user_id: this.currentUser.user_id,
              event_id: this.event,
              lead_id: this.detailsClient.lead_id,
            };
            const { data } = await amgApi.post(
              "sales-made/copy-settlements-first-program",
              params
            );
          }
          this.modalOpenAddService = false;
          this.$router
            .push({ name: "sales-made-boostcredit-client-pending" })
            .catch((err) => {
              console.log(err);
            });
          this.removePreloader();
        }
      } catch (error) {
        this.showErrorSwal(
          "Please, verify if the client already has the service, otherwise contact support.",
          "Error"
        );
      } finally {
        this.removePreloader();
      }
    },
    async hideModalProgram(refresh) {
      if (refresh) {
        // await this.updateRow();
        this.showToast(
          "success",
          "top-right",
          "Success!",
          "CheckIcon",
          "Successful operation"
        );
      }

      this.modalData.programs.programSelected = "";
      this.modal.programs = false;
      this.removePreloader();
    },
    formatMoney(value) {
      const formater = new Intl.NumberFormat("en-US", {
        currency: "USD",
        style: "currency",
      }).format(Number(value ?? 0));
      return formater;
    },
    async getAllStatusClientAccount() {
      this.filters[3].options = [];
      const { data } = await NcrLeadsService.getAllStatusClientAccount();
      this.filters[3].options.push({
        value: 0,
        label: "All",
        icon: "CircleIcon",
      });
      this.filters[3].options.push(...data);
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    lengthClients() {
      return this.clientsSms.length == 0;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
    paymentType: {
      get() {
        return this.filters[4].model;
      },
      set(value) {
        this.filters[4].model = value;
      },
    },
    paymentDay: {
      get() {
        return this.filters[5].model;
      },
      set(value) {
        this.filters[5].visible = value;
      },
    },
  },
  watch: {
    paymentType(newVal) {
      if (newVal == 1) {
        this.paymentDay = true;
      } else {
        this.paymentDay = false;
      }
    },
  },
};
</script>

<style scoped>
#icon {
  border-radius: 50%;
  margin-bottom: 2px;
  margin-right: 5px;
}
</style>
