export default [
  {
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "From",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: { year: "numeric", month: "numeric", day: "numeric" },
    cols: 6,
    visible: true,
  },
  {
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "To",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: { year: "numeric", month: "numeric", day: "numeric" },
    cols: 6,
    visible: true,
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Advisor",
    model: null,
    options: [],
    reduce: "id",
    selectText: "user_name",
    cols: 12,
    visible: true,
  },
  {
    type: "custom-select",
    margin: true,
    showLabel: true,
    label: "Client Status",
    model: null,
    options: [],
    reduce: "value",
    selectText: "label",
    cols: 12,
    visible: true,
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Payment Type",
    model: null,
    options: [
      { id: 0, text: "All" },
      { id: 1, text: "Automatic" },
      { id: 2, text: "Manual" },
      { id: 3, text: "Others" },
    ],
    reduce: "id",
    selectText: "text",
    cols: 6,
    visible: true,
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Payment Day",
    model: null,
    options: [
      { value: 0, label: "All" },
      { value: 5, label: "5" },
      { value: 10, label: "10" },
      { value: 15, label: "15" },
      { value: 20, label: "20" },
      { value: 25, label: "25" },
      { value: 30, label: "30" },
    ],
    reduce: "value",
    selectText: "label",
    cols: 6,
    visible: true,
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Score",
    model: null,
    options: [
      { id: null, text: "All" },
      { id: 0, text: "0" },
      { id: 1, text: "300-400" },
      { id: 2, text: "401-500" },
      { id: 3, text: "500-600" },
      { id: 4, text: "600-650" },
      { id: 5, text: "651-700" },
      { id: 6, text: "701-750" },
      { id: 7, text: "751-800" },
      { id: 8, text: "801-850" },
    ],
    reduce: "id",
    selectText: "text",
    cols: 6,
    visible: true,
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Type AC",
    model: null,
    options: [
      { id: null, text: "All" },
      { id: 1, text: "UB ONLINE" },
      { id: 2, text: "EMAIL" },
      { id: 3, text: "MONITOREO EXPERIAN" },
      { id: 4, text: "MONITOREO TRANSUNION" },
      { id: 5, text: "MONITOREO EQUIFAX" },
      { id: 6, text: "OTHER" },
    ],
    reduce: "id",
    selectText: "text",
    cols: 6,
    visible: true,
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Goals",
    model: null,
    options: [{ name: null, id: null }],
    reduce: "id",
    selectText: "name",
    cols: 12,
    visible: true,
  },
];
